import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('date_picker', (date = (new Date())) => ({
  MONTH_NAMES: ["januar","februar","marts","april","maj","juni","juli","august","september","oktober","november","december"],
  MONTH_SHORT_NAMES: ["jan","feb","mar","apr","maj","jun","jul","aug","sep","okt","nov","dec"],
  DAYS: ["man", "tir", "ons", "tor", "fre", "lør", "søn"],
  showDatepicker: false,
  today: new Date(),
  selectedDate: null,
  selectedDateString: '',
  displayMonth: null,
  displayYear: null,
  calendar: [],
  init () {
    this.today = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate());
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    this.displayYear = date.getFullYear();
    this.displayMonth = date.getMonth();
    this.pickDate(date.getDate(), false);
    this.generateCalendar();
  },
  isSelectedDate (date) {
    const d = new Date(this.displayYear, this.displayMonth, date);
    return d.getTime() == this.selectedDate.getTime();
  },
  isToday (date) {
    const d = new Date(this.displayYear, this.displayMonth, date);
    return d.getTime() == this.today.getTime();
  },
  pickDate (date, triggerEvent = true) {
    this.selectedDate = new Date(this.displayYear, this.displayMonth, date);
    this.selectedDateString = this.format(this.selectedDate);
    this.hide();
    if (triggerEvent) {
      console.log(this.selectedDateString);
      this.$dispatch('frop_date_picker_update', this.selectedDateString);
    }
    this.generateCalendar();
  },
  format (date) {
    const YYYY = `${date.getFullYear()}`;
    const MM = `${date.getMonth() + 1}`.padStart(2, '0');
    const DD = `${date.getDate()}`.padStart(2, '0');
    return `${YYYY}-${MM}-${DD}`;
  },
  daysInMonth (year, month) {
    return new Date(year, month + 1, 0).getDate();
  },
  firstDayOfMonth(year, month) {
    return new Date(year, month, 1).getDay();
  },
  generateCalendar () {
    let numBlankDays = (this.firstDayOfMonth(this.displayYear, this.displayMonth) + 7 - 1) % 7;
    let numDays = this.daysInMonth(this.displayYear, this.displayMonth);
    let blankDays = Array(numBlankDays).fill({date:''});
    let days = Array(numDays).fill(null);
    for (let i = 0; i < days.length; i++) {
      const d = i + 1;
      days[i] = {
        date: d,
        isToday: this.isToday(d),
        isSelectedDate: this.isSelectedDate(d),
      };
    }
    this.calendar = blankDays.concat(days);
  },
  toggle () {
    this.showDatepicker = !this.showDatepicker;
  },
  show () {
    this.showDatepicker = true;
  },
  hide () {
    this.showDatepicker = false;
  },
  addMonth(numMonths = 0) {
    let m = new Date(this.displayYear, this.displayMonth, 1);
    m.setMonth(m.getMonth() + numMonths);
    this.displayYear = m.getFullYear();
    this.displayMonth = m.getMonth();
    this.generateCalendar();
  }
}));